import React from 'react'
import Layout from '../layout/layout'

import Splash from '../index/splash';
import Providers from '../index/providers'

import '../../static/css/main.css';

function Index() {
  return (
    <Layout>
      <Splash />
      <Providers />
    </Layout>
  );
}

export default Index;
