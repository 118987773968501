import React from 'react';
import c from 'classnames';

import styles from './providers.module.css';

export default function Providers() {
  return (
    <section className={c(styles.providers, 'py-4', 'py-md-5')}>
      <div className={c('container')}>
        <div className="row">
          <div className="col-12 col-md-6 mb-4 mb-md-0">
            <div className="card">
              <div className="card-body">
                <p className="text-medium mt-0">Are you a new client?</p>
                <p className="card-text">If you have not yet registered with the clinic, please contact us by phone or email.</p>
                <a className="m-0 green d-flex align-items-center mb-2 mr-3" href="tel:12044824044">
                  <i className="fa fa-phone mr-3" />
                  (204) 482-4044
                </a>
                <a className="m-0 green d-flex align-items-center" href="mailto:bookings@eastonplace.ca">
                  <i className="fa fa-envelope mr-3" />
                  bookings@eastonplace.ca
                </a>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="card">
              <div className="card-body">
                <p className="text-medium mt-0">
                  Are you a registered client?
                </p>
                <p className="card-text">
                  If you are an existing patient of the clinic, click the button below to book an appointment.
                </p>
                <a
                  className={c(styles.btnRegister, 'btn btn-primary mt-3 px-3 py-2')}
                  href="https://book.libremd.com/book/book.php?c=easton"
                  target="_blank"
                  title="Book an appointment"
                  rel="noopener noreferrer"
                >
                  Book appointment  →
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}