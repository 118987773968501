import React from 'react';
import c from 'classnames';
import Link from 'gatsby-link';

import BannerImage from '../../static/img/banner.jpg';
import BannerMobileImage from '../../static/img/banner-mobile.jpg';
import LogoImage from '../../static/img/logo.svg';

import styles from './splash.module.css';

export default function Splash() {
  return (
    <section className={c(styles.splash)}>
      <img src={BannerImage} className="d-none d-md-block" style={{ width: '100%' }} alt="Easton Place Medical Centre" />
      <img src={BannerMobileImage} className="d-block d-md-none" style={{ width: '100%' }} alt="Easton Place Medical Centre" />
      <div className={c(styles.splashContent, 'py-4', 'py-md-5')}>
        <div className="container">
          <div className="row">
            <div className="d-none d-md-block col-2">
              <img src={LogoImage} className="px-5 px-md-0" style={{ width: '100%' }} alt="Easton Place logo" />
            </div>
            <div className="col-12 col-md-10 pl-md-5">
              <p className="h3">
                Welcome to Easton Place!
              </p>
              <p>
                Easton Place is a brand new, state of the art facility built with whole body wellness in mind.
              </p>
              <p>
                Encompassing family medicine, walk-ins, women's health, dental care, pharmacy, audiology, and more, our clinic provides a wide array of healthcare services in a single convenient location.
              </p>
              <p>
                We are now offering free prescription delivery within Selkirk. Visit our <Link to="/pharmacy">pharmacy</Link> page for more information.
              </p>
              <hr />
              <p className="h4">Quick Links</p>
              <p>
                <ul>
                  <li><Link to="/walk-in">Walk-in Clinic</Link></li>
                  <li><Link to="/pharmacy">Pharmacy</Link></li>
                  <li>
                    <a
                      href="http://redriverdental.ca"
                      title="Red River Dental"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Red River Dental
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://horizonhearing.com"
                      title="Horizon Hearing Centres"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Horizon Hearing Centres
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://eastonplacemassage-interlakelymphclinic.noterro.com/"
                      title="Interlake Lymph Clinic"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Interlake Lymph Clinic
                    </a>
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}